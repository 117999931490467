<template>
    <div class="BatchPlan">
        <div class="padd20 borbut20" style="position: relative;">
            <div class="topSearchSou">
                <div class="searchdan">
                    <div class="title">用户姓名：</div>
                    <el-select v-model="discourse" filterable remote reserve-keyword placeholder="请输入姓名" :remote-method="remoteMethod" :loading="loading">
                        <el-option v-for="item in recommended" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                    <!-- <el-select v-model="discourse" filterable placeholder="请选择">
                        <el-option v-for="item in recommended" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select> -->
                    <div class="title" style="margin-left:20px">时间：</div>
                    <el-date-picker v-model="date_list" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                    </el-date-picker>
                    <!-- <el-date-picker v-model="pickerTime" type="date" placeholder="选择日期" @change="selectPickerTime">
                    </el-date-picker> -->
                    <!-- <el-input v-model="uri_name" placeholder="请输入搜索标题" class="params" @keyup.enter.native="onSearch()"></el-input> -->
                </div>
                <el-button type="primary" icon="el-icon-search" @click="onSearch()">搜索</el-button>
                <el-button class="Shuarefresh" @click="$router.go(0)" icon='el-icon-refresh-right'>重置数据</el-button>
            </div>
        </div>
        <div class="TableList">
            <div class="flex">
                <div>
                    <span>内部访问数 : </span>
                    <span>{{inner_total}}</span>
                </div>
                <div>
                    <span>内部推荐访问数 : </span>
                    <span>{{inner_tuijian_total}}</span>
                </div>
                <div>
                    <span>外部访问数 : </span>
                    <span>{{out_total}}</span>
                </div>
            </div>
        </div>
        <div class="TableList padd20" style="padding-top:10px">
            <div class="duoshao">
                <div class="title">批量计划访问统计</div>
                <!-- <div class="lvtotal">
                    总条数：{{lvtotal}} 条
                </div> -->
                <div class="lvtotal">总条数：{{lvtotal}} 条 <div class="lvtotal riri" style="margin-left:5px">
                        每页显示:
                        <el-select v-model="per_page" placeholder="10" @change="selectTrigger()" style="margin-left:20px;margin-right:20px;width:80px">
                            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                        条
                    </div>
                </div>
            </div>
            <el-table ref="filterTable" :data="ErShopList" style="width: 100%">
                <el-table-column prop="shop_info" label="姓名">
                    <template slot-scope="scope">
                        <div>{{scope.row.name}}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="ad_user" label="访问方式">
                    <template slot-scope="scope">
                        <div v-if="scope.row.is_inner==1">内部访问</div>
                        <div v-if="scope.row.is_inner==2">外部访问</div>
                        <div v-if="scope.row.is_inner==3">内部推荐访问</div>
                    </template>
                </el-table-column>
                <el-table-column prop="count" label="次数">
                </el-table-column>
            </el-table>
        </div>
        <el-pagination class="lv-page" :page-size="per_page" :pager-count="11" @current-change="DialogCurrentChange" background layout="prev, pager, next, jumper" :total="lvtotal" hide-on-single-page :current-page.sync="paramPage"></el-pagination>
    </div>
</template>

<script>
export default {
    data() {
        return {
            uri_name: "",
            paramData: {}, //表格参数
            shopid: "", //参数
            pickerTime: "", //日期参数
            inner_total: "",  //内部数量
            out_total: "",  //外部数量
            inner_tuijian_total: "",
            per_page: 20, //一页多少条
            lvtotal: null, //共几条数据
            paramPage: 1, //表格当前第几页
            shop_title: "",
            ErShopList: [], //数据
            particularsList: [], //详情数据
            detailsTime: "", //详情日期
            // particulars: {
            //     //详情分页
            //     per_page: 8, //一页多少条
            //     lvtotal: null, //共几条数据
            //     paramPage: 1, //表格当前第几页
            // },
            detailsData: {}, //  详情数据
            params: "", //搜索参数
            particularsID: "",
            uri_name: "",
            phone: "",
            options: [
                {
                    value: 25,
                    label: "25",
                },
                {
                    value: 50,
                    label: "50",
                },
                {
                    value: 100,
                    label: "100",
                },
            ],
            recommended: [],
            discourse: '',
            value: [],
            list: [],
            loading: false,
            date_list: []
        };
    },

    mounted() {
        this.getAdUserLogList();
        this.getAdUserTotal();
        this.getAdUserList()
    },
    components: {},
    methods: {
        remoteMethod(query) {
            if (query !== '') {
                this.loading = true;
                setTimeout(() => {
                    var param = {
                        name: query
                    }
                    this.$service.get(this.$api.getAdUserList, param, (res) => {
                        this.loading = false;
                        if (res.code == "200") {
                            this.recommended = res.data.data;
                        }
                    });
                }, 200);
            } else {
                this.recommended = [];
            }
        },
        //用户列表
        getAdUserList() {
            this.recommended = [];
            this.$service.get(this.$api.getAdUserList, "", (res) => {
                if (res.code == "200") {
                    console.log(this.recommended)
                    this.list = res.data.data;
                }
            });
        },
        // 访问计划统计
        getAdUserTotal() {
            var param = {
                start_time: this.paramData.start_time,
                end_time: this.paramData.end_time,
                user_id: this.discourse
            }
            this.$service.get(this.$api.getAdUserTotal, param, (res) => {
                if (res.code == "200") {
                    console.log(res)
                    this.inner_total = res.data.inner_total;
                    this.out_total = res.data.out_total;
                    this.inner_tuijian_total = res.data.inner_tuijian_total;
                }
            });
        },
        //显示条数改变
        selectTrigger() {
            this.paramPage = 1;
            this.paramData.per_page = this.per_page;
            this.getAdUserLogList();
        },
        // 获取昨天
        getDay(day) {
            var today = new Date();
            var targetday_milliseconds =
                today.getTime() + 1000 * 60 * 60 * 24 * day;
            today.setTime(targetday_milliseconds); //注意，这行是关键代码
            var tYear = today.getFullYear();
            var tMonth = today.getMonth();
            var tDate = today.getDate();
            tMonth = this.doHandleMonth(tMonth + 1);
            tDate = this.doHandleMonth(tDate);
            return tYear + "-" + tMonth + "-" + tDate;
        },
        doHandleMonth(month) {
            var m = month;
            if (month.toString().length == 1) {
                m = "0" + month;
            }
            return m;
        },
        selectPickerTime() {
            if (this.pickerTime != null) {
                this.pickerTime = this.dateFtt("yyyy-MM-dd", this.pickerTime);
            }
        },
        //页面搜索
        onSearch() {
            if (this.date_list.length == 0) {
                var start_time = "";
                var end_time = "";
            } else {
                var start_time = this.dateFtt("yyyy-MM-dd", this.date_list[0]);
                var end_time = this.dateFtt("yyyy-MM-dd", this.date_list[1]);
            }
            this.paramData = {
                date: this.pickerTime, //搜索日期
                user_id: this.discourse,
                start_time: start_time,
                end_time: end_time
            };
            this.paramPage = 1;
            this.getAdUserLogList();
            this.getAdUserTotal();
        },
        // 点击图片
        onImg(img) {
            window.open(
                "https://user.erlangcha.com/advances/Advimage?imageurl=" + img
            );
        },
        // 分页
        DialogCurrentChange(val) {
            this.paramPage = val;
            this.getAdUserLogList();
        },
        // 详情分页
        particularsChange(val) {
            this.particulars.paramPage = val;
            this.onParticulars();
        },
        //数据列表
        getAdUserLogList() {
            this.ErShopList = [];
            var param = this.paramData;
            console.log(param)
            param.page = this.paramPage;
            param.pageSize = this.per_page;
            this.$service.get(this.$api.getAdUserLogList, param, (res) => {
                if (res.code == "200") {
                    this.ErShopList = res.data.data;
                    this.lvtotal = res.data.total;
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.BatchPlan {
    .lvtotal {
        line-height: 33px;
    }
    .img {
        width: 40px;
        height: 40px;
    }
    .Souxiangqing {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
    }
    .flex {
        display: flex;
        justify-content: space-around;
        div {
            width: 160px;
            height: 80px;
            text-align: center;
            line-height: 80px;
            font-size: 18px;
        }
    }
    .pan {
        word-break: break-all;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1 !important;
        overflow: hidden;
    }
}
</style>
<style lang="scss">
.BatchPlan {
    .el-dialog__header {
        background: #0075f7;
        padding-bottom: 15px;
    }
    .el-dialog__title {
        color: #fff;
    }
    .el-input__icon {
        line-height: 35px;
    }
    .params {
        width: 180px !important;
        margin-left: 20px;
    }
    .el-table td,
    .el-table th {
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .is-in-pagination {
        width: 50px;
        margin-left: 10px;
        margin-right: 10px;
        text-align: center !important;
    }
    .el-pagination__jump .el-input__inner {
        text-align: center !important;
    }
    .img {
        width: 100px !important;
        height: 100px !important;
    }
    .el-date-editor .el-range-separator {
        width: 8% !important;
        line-height: 35px;
    }
    .el-range-editor.el-input__inner {
        padding: 0 !important;
    }
}
.testtooltip {
    width: 200px;
}
</style>